import './style.scss';
import { processZipFile } from './processZipFile';
import { cleanLibs, loadLibs } from './LibsManagement';
import { hackDomHostspotCss, overrideInsideJs } from './overrideInside';
import { createViewer, destroyViewer } from './viewer';
import { cleanCache } from './UrlsCache';
const htmlBody = document.querySelector('body');
const dropZone = document.querySelector('#dropzone');
const divInside = document.querySelector('#inside');
const spanMessage = document.querySelector('#message');
const fileInput = document.querySelector('#inputfile');
export const debug = new URLSearchParams(window.location.search).get('debug') !== undefined;
dropZone.style.visibility = 'visible';
htmlBody.ondragover = (e) => {
    e.preventDefault();
};
htmlBody.ondrop = (event) => {
    event.preventDefault();
    displayMessage('Chargement...');
    clearCurrentTourAndRestartWithDropZone();
    loadFile(getFile(event));
};
document.addEventListener('keydown', function (event) {
    if (event.ctrlKey && event.key === 'r') {
        clearCurrentTourAndRestartWithDropZone();
    }
});
dropZone.onclick = () => {
    fileInput.click();
};
fileInput.onchange = () => {
    const selectedFile = fileInput.files[0];
    loadFile(selectedFile);
};
function loadFile(file) {
    if (debug) {
        console.log('loadFile : ' + file.name);
    }
    processZipFile(file)
        .then((json) => {
        return loadLibs()
            .then(() => overrideInsideJs())
            .then(() => {
            return createViewer(divInside, json)
                .then((viewer) => hackDomHostspotCss(viewer))
                .then(() => {
                display('viewer');
                displayMessage();
            });
        });
    })
        .catch((err) => console.error(err));
}
function clearCurrentTourAndRestartWithDropZone() {
    destroyViewer();
    display('dropzone');
    cleanLibs();
    cleanCache();
}
function getFile(event) {
    if (event.dataTransfer.items) {
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
            if (event.dataTransfer.items[i].kind === 'file') {
                return event.dataTransfer.items[i].getAsFile();
            }
        }
    }
    else {
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
            return event.dataTransfer.files[i];
        }
    }
}
function displayMessage(message = 'Déposer votre archive ici ou cliquer') {
    spanMessage.innerHTML = message;
}
displayMessage();
function display(mode) {
    if (mode === 'dropzone') {
        dropZone.style.display = 'block';
        divInside.style.display = 'none';
    }
    else {
        dropZone.style.display = 'none';
        divInside.style.display = 'block';
    }
}
